<template>
  <v-container>
    <v-row class="ma-0" justify="center">
      <h2>Kóða vantar</h2>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>
